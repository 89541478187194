import { Button, Lucide, TableBase } from '@kerplunkai/common-components';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

interface QuestionRowActionsProps {
  isLoading: boolean;
  onToggleLock?: () => void;
  onRemoveQuestion?: () => void;
  isLocked?: boolean;
  dndListeners?: SyntheticListenerMap;
  dndAttributes?: DraggableAttributes;
}

function QuestionRowActions({
  isLoading,
  onToggleLock,
  onRemoveQuestion,
  isLocked = false,
  dndListeners,
  dndAttributes,
}: QuestionRowActionsProps) {
  return (
    <TableBase.Td className="min-w-32 whitespace-nowrap !pl-4 text-slate-500">
      <div className="flex justify-between">
        <Button
          disabled={isLoading}
          variant="text"
          onClick={() => onToggleLock?.()}
        >
          <Lucide
            color={isLocked ? '#558408' : '#64748B'}
            icon={isLocked ? 'LockKeyhole' : 'UnlockKeyhole'}
            className={`size-5 stroke-[1.3] ${
              isLocked ? 'text-[#558408]' : 'stroke-slate-500'
            }`}
          />
        </Button>
        <Button
          disabled={isLoading}
          variant="text"
          {...dndAttributes}
          {...dndListeners}
        >
          <Lucide
            icon="Move"
            className="size-5 stroke-slate-500 stroke-[1.3]"
          />
        </Button>
        <Button
          disabled={isLoading}
          variant="text"
          onClick={() => onRemoveQuestion?.()}
        >
          <Lucide
            icon="Trash2"
            className="size-5 stroke-slate-500 stroke-[1.3]"
          />
        </Button>
      </div>
    </TableBase.Td>
  );
}

export { QuestionRowActions };
