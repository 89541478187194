/* eslint-disable react/no-array-index-key */
import { CodeBlock } from '@typings';
import { CodeMirrorBlock } from '@components';
import { cloneDeep } from 'lodash';

interface QuestionCodeBlocksProps {
  codeBlocks?: CodeBlock[];
  questionIndex: number;
  onQuestionChange: (
    value: string | CodeBlock[],
    name: string,
    index: number,
    clearSuggestedAnswers?: boolean,
  ) => void;
}

function QuestionCodeBlocks({
  codeBlocks,
  onQuestionChange,
  questionIndex,
}: QuestionCodeBlocksProps) {
  if (!codeBlocks) return null;

  return codeBlocks
    .filter(({ content, language }) => content && language)
    .map((codeBlock, curIndex) => (
      <div key={`code-block-${curIndex}`} className="mt-2">
        <CodeMirrorBlock
          block={codeBlock}
          onChange={(value: string) => {
            const newCodeBlocks = cloneDeep(codeBlocks || []);

            newCodeBlocks[curIndex].content = value;

            onQuestionChange(newCodeBlocks, 'code_blocks', questionIndex);
          }}
        />
      </div>
    ));
}

export { QuestionCodeBlocks };
