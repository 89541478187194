import {
  GenQuestionsResponse,
  Job,
  JobBenefit,
  JobExperienceLevel,
  JobQuestion,
  JobRequest,
  JobSchedule,
  JobType,
  Organization,
  PayType,
} from '@typings';
import { INITIAL_JOB } from '@constants';
import { formatCurrency, isNumber, parseJSON } from '@utilities';

export const getJobStatusColor = (status: string) => {
  switch (status) {
    case 'closed':
      return 'red-700';

    case 'draft':
      return 'yellow-600';

    default:
      return '[#558408]';
  }
};

export const mapJobToJobForm = ({
  benefits,
  categories,
  subcategories,
  interview_questions,
  industry,
  ...job
}: Job): JobRequest => {
  return {
    ...job,
    organization_id: '',
    hiring_organization_id: '',
    benefit_ids: benefits.map(benefit => benefit.id),
    job_category_ids: categories.map(category => category.id),
    job_subcategory_ids: subcategories.map(category => category.id),
    generate_coding_questions: false,
    interview_questions_attributes: interview_questions,
    display_compensation:
      job.display_compensation === null ? false : job.display_compensation,
    industry_id: industry?.id,
  };
};

export const getInitialJobValue = (
  orgJob: Job | undefined,
  selectedOrg: Organization,
  clients: Organization[],
  isCreate: boolean,
) => {
  let initialJob = INITIAL_JOB;

  if (orgJob) {
    initialJob = mapJobToJobForm(orgJob);

    if (orgJob.hiring_organization) {
      if (orgJob.hiring_organization === selectedOrg.name)
        initialJob.hiring_organization_id = selectedOrg.id;
      else {
        const client = clients.find(c => c.name === orgJob.hiring_organization);

        if (client) {
          initialJob.hiring_organization_id = client.id;
          initialJob.industry_id = client.industry.id;
        }
      }
    }
  }

  // initialize intro video only when creating a new posting, from org details
  if (isCreate) initialJob.intro_video_url = selectedOrg.intro_video_url;

  if (!initialJob.industry_id) initialJob.industry_id = selectedOrg.industry.id;
  if (!initialJob.hiring_organization_id)
    initialJob.hiring_organization_id = selectedOrg.id;

  return { ...initialJob, organization_id: selectedOrg.id };
};

export const mapGenQuestions = (
  curQuestions: JobQuestion[],
  genQuestions: GenQuestionsResponse,
) => {
  const newQuestions = [...curQuestions];

  const isDuplicate = (content: string) =>
    curQuestions.some(question => question.content === content);

  newQuestions.push(
    ...genQuestions.cultural_questions
      .filter(question => !isDuplicate(question))
      .map((question, index) => ({
        content: question,
        locked: false,
        position: newQuestions.length + 1 + index,
        generated_by_ai: true,
        category: 'culture',
      })),
  );

  newQuestions.push(
    ...genQuestions.technical_questions
      .filter(question => {
        const content =
          typeof question === 'object' && 'content' in question
            ? question.content
            : question;
        return !isDuplicate(content);
      })
      .map((question, index) => {
        if (typeof question === 'object' && 'content' in question) {
          return {
            content: question.content,
            code_blocks: question.code_blocks,
            locked: false,
            position: newQuestions.length + 1 + index,
            generated_by_ai: true,
            category: 'technical',
          };
        }
        return {
          content: question,
          locked: false,
          position: newQuestions.length + 1 + index,
          generated_by_ai: true,
          category: 'technical',
        };
      }),
  );

  newQuestions.push(
    ...genQuestions.personal_questions
      .filter(question => !isDuplicate(question))
      .map((question, index) => ({
        content: question,
        locked: false,
        position: newQuestions.length + 1 + index,
        generated_by_ai: true,
        category: 'personal',
      })),
  );

  return newQuestions;
};
export const getJobType = (type: keyof typeof JobType) => {
  return JobType[type];
};

export const getJobExperienceLevel = (
  expLevel: keyof typeof JobExperienceLevel,
) => {
  return JobExperienceLevel[expLevel];
};

export const getPayType = (payType: keyof typeof PayType): PayType => {
  return PayType[payType];
};

export const getJobBenefit = (benefit: JobBenefit): string => {
  return benefit.name;
};

export const getJobSchedule = (schedule: keyof typeof JobSchedule): string => {
  return JobSchedule[schedule];
};

export const getJobPay = (
  payType: keyof typeof PayType,
  salary?: string,
  minRange?: string,
  maxRange?: string,
): string => {
  // case: we have a singular salary that could be hourly or salary
  if (salary && isNumber(salary)) {
    const number = salary as unknown as number;
    return `${formatCurrency(number, 'USD')} ${
      payType === 'per_hour' ? 'Per Hour' : 'Per Year'
    }`;
  }

  // use range if salary not spefied, could be hourly or annual
  if (
    minRange !== undefined &&
    maxRange !== undefined &&
    isNumber(minRange) &&
    isNumber(maxRange)
  ) {
    const minRangeNum = minRange as unknown as number;
    const maxRangeNum = maxRange as unknown as number;
    return `${formatCurrency(minRangeNum, 'USD')}-${formatCurrency(
      maxRangeNum,
      'USD',
    )} ${payType === 'per_hour' ? 'Per Hour' : 'Per Year'}`;
  }

  // default
  return 'Unspecified';
};

export const getJobLocation = (jobLocation: string, includeComma = false) => {
  const googJobLoc = parseJSON(jobLocation);
  let str = '';

  if (googJobLoc) {
    let city = '';
    let state = '';
    let country = '';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (googJobLoc as any).address_components?.forEach((component: any) => {
      const componentType = component.types[0];

      switch (componentType) {
        case 'locality':
        case 'postal_town':
          city = component.long_name;
          break;

        case 'administrative_area_level_1': {
          state = component.short_name;
          break;
        }

        case 'country':
          country = component.short_name;
          break;

        default:
          break;
      }
    });

    if (city) str = city;
    if (state) str += str ? `, ${state}` : state;
    if (country) str += str ? `, ${country}` : country;
  } else {
    str = jobLocation !== ', ' ? jobLocation : '';
  }

  return `${includeComma ? ', ' : ''}${str}`;
};
