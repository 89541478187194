/* eslint-disable @typescript-eslint/naming-convention */
import spacetime from 'spacetime';
import { cloneDeep } from 'lodash';

import { Interview, InterviewRequest, InterviewStatus } from '@typings';

export const combineDayTime = (day: string, time: string): string => {
  const dt = spacetime(`${day} ${time}`);

  return dt.toNativeDate().toISOString();
};

export const mapToInterviewRequest = (
  {
    interview_at_day,
    interview_at_time,
    interview_candidate,
    job_listing,
    ...interview
  }: InterviewRequest,
  orgId: string,
): InterviewRequest => {
  const interviewRequest = cloneDeep(interview);

  if (interview_at_day && interview_at_time)
    interviewRequest.interview_at = combineDayTime(
      interview_at_day,
      interview_at_time,
    );

  if (interview_candidate)
    interviewRequest.interview_attendees_attributes = [
      {
        email: interview_candidate,
        is_candidate: true,
      },
      ...interview.interview_attendees_attributes,
    ];

  return {
    ...interviewRequest,
    organization_id: orgId,
  };
};

export const mapToStatusInterviewRequest = ({
  title,
  description,
  job_listing,
  interview_at,
  status,
  interview_type,
  id,
  interview_attendees,
}: Interview): InterviewRequest => ({
  id,
  title: title as string,
  description: description as string,
  interview_type: interview_type as string,
  status,
  job_listing_id: job_listing.id,
  interview_at: interview_at as string,
  interview_attendees_attributes: interview_attendees || [],
  send_emails: false,
});

export const getInitialInterview = (
  interview?: Interview,
): InterviewRequest => {
  if (!interview)
    return {
      title: '',
      description: '',
      interview_type: 'copilot',
      status: 'scheduled',
      job_listing_id: '',
      interview_at: '',
      interview_at_day: spacetime(new Date()).format(
        '{month-short} {date}, {year}',
      ),
      interview_at_time: '',
      interview_candidate: '',
      interview_attendees_attributes: [
        {
          email: '',
          is_candidate: false,
        },
      ],
      send_emails: false,
    };

  const {
    title,
    description,
    job_listing,
    interview_at,
    status,
    interview_type,
    id,
  } = interview;

  return {
    id,
    title: title as string,
    description: description as string,
    interview_type: interview_type as string,
    status: status as keyof typeof InterviewStatus,
    job_listing_id: job_listing.id,
    interview_at: interview_at as string,
    interview_at_day: spacetime(new Date(interview_at as string)).format(
      '{date} {month-short}, {year}',
    ),
    interview_at_time: spacetime(new Date(interview_at as string)).unixFmt(
      'hh:mm a',
    ),
    interview_attendees_attributes:
      interview.interview_attendees?.filter(
        interview_attendee => !interview_attendee.is_candidate,
      ) || [],
    interview_candidate:
      interview.interview_attendees?.find(
        interview_attendee => interview_attendee.is_candidate,
      )?.email || '',
    send_emails: false,
    job_listing,
  };
};
