import {
  Button,
  FormCheckControl,
  Lucide,
  TableBase,
} from '@kerplunkai/common-components';
import { JobQuestion } from '@typings';

interface YesNoSuggestedAnswersProps {
  question: JobQuestion;
  isLoading?: boolean;
  onRemoveAnswer?: (questionIndex: number, answerIndex: number) => void;
  onAnswerChange?: (value: string, answerIndex: number, index: number) => void;
  questionIndex: number;
}

function YesNoSuggestedAnswers({
  question,
  isLoading = false,
  onRemoveAnswer,
  onAnswerChange,
  questionIndex,
}: YesNoSuggestedAnswersProps) {
  const radioButtonClass = 'bg-white px-3 py-2 rounded-md';

  return (
    <TableBase.Tr className="w-full bg-[#F4F6F8]">
      <TableBase.Td className="!whitespace-normal align-top" colSpan={4}>
        <div className="flex flex-col">
          <FormCheckControl
            className="mt-5"
            description="Select 'Yes' or 'No' for questions with preferred answers. Leave blank for informational-only questions."
            disabled={isLoading}
            label="Yes/No Answer"
            name="yes_no_question"
            items={[
              { label: 'Yes', value: 'true', className: radioButtonClass },
              { label: 'No', value: 'false', className: radioButtonClass },
            ]}
            type="radio"
            value={question.suggested_answers?.[0] || ''}
            onChange={evt =>
              onAnswerChange?.(evt.target.value, 0, questionIndex)
            }
          />
        </div>
      </TableBase.Td>
      <TableBase.Td className="w-full px-0 text-right" colSpan={1}>
        <Button
          className="mr-5"
          disabled={isLoading}
          variant="text"
          onClick={() => onRemoveAnswer?.(questionIndex, 0)}
        >
          <Lucide
            icon="Trash2"
            className="size-5 stroke-slate-500 stroke-[1.3]"
          />
        </Button>
      </TableBase.Td>
    </TableBase.Tr>
  );
}

export { YesNoSuggestedAnswers };
