/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/naming-convention */
import clsx from 'clsx';
import * as Yup from 'yup';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FormActions, FormCard } from '@kerplunkai/common-components';
import { isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import {
  GenQuestionsResponse,
  Job,
  JobQuestion,
  JobRequest,
  JobStatus,
} from '@typings';
import { JobInfoStep } from '@modules/jobs/jobForms/jobInfoStep.component';
import { JobInterviewForm } from '@modules/jobs/jobForms/jobInterviewForm.component';
import { JobPreview } from '@modules/jobs/jobPreview';
import { NAV_ROUTES, NEW_ROUTES } from '@constants';
import { mapGenQuestions, mapJobToJobForm } from '@utilities/jobs';
import {
  selectJobDesriptionLoading,
  selectSelectedOrgId,
} from '@store/selectors';
import {
  useCreateJobMutation,
  useGeneratePublishJobQuestionsMutation,
  useOrganizationsDetailsQuery,
  useUpdateJobMutation,
} from '@store/services';

interface JobFormProps {
  activeStep: number;
  isLastStep: boolean;
  validationSchema: Yup.AnyObjectSchema;
  onStepChange: Dispatch<SetStateAction<number>>;
}

function JobForm({
  activeStep,
  isLastStep,
  validationSchema,
  onStepChange,
}: JobFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    values: job,
    setFieldValue,
    setValues,
    validateForm,
    validateField,
  } = useFormikContext<JobRequest>();

  const [createJob, { isLoading: isCreating }] = useCreateJobMutation();
  const [updateJob, { isLoading: isUpdating }] = useUpdateJobMutation();
  const [generateQuestions, { isLoading: isGenerating }] =
    useGeneratePublishJobQuestionsMutation();
  useOrganizationsDetailsQuery();

  const orgId = useSelector(selectSelectedOrgId);
  const isLoadingDescription = useSelector(selectJobDesriptionLoading);

  const [generatedQuestions, setGeneratedQuestions] =
    useState<null | GenQuestionsResponse>(null);

  // take the new questions from the AI endpoint and add them to the iqa array
  // they need to be inserted after the active and locked questions, but before any _destroy questions
  // to keep position numbering/indexing intact
  useEffect(() => {
    if (generatedQuestions) {
      // mark all unlocked questions with an id with _destroy
      // so our API can remove them when we save the listing
      let questions = [...job.interview_questions_attributes];
      questions = questions.map(q => {
        return {
          ...q,
          _destroy: (!!q.id && !q.locked) || undefined,
        };
      });

      // re-index positions for the active and locked questions
      const activeAndLockedQuestions = questions
        .filter(q => !q._destroy && q.locked)
        .map((q, index) => {
          return {
            ...q,
            position: index + 1,
          };
        });

      const mappedGenQuestions = mapGenQuestions(
        activeAndLockedQuestions,
        generatedQuestions as GenQuestionsResponse,
      );

      // retain newly-destroyed questions after AI gen
      const questionsToDestroy = questions.filter(q => q._destroy);
      const toUpdate = [...mappedGenQuestions, ...questionsToDestroy];
      setFieldValue('interview_questions_attributes', toUpdate);

      setGeneratedQuestions(null);
    }
  }, [generatedQuestions, job, setFieldValue, setGeneratedQuestions]);

  const handleJobChange = useCallback(
    async (
      name: string,
      value: string | boolean | number | string[] | JobQuestion[],
    ) => {
      await setFieldValue(name, value);

      if (validationSchema.fields[name]) validateField(name);
    },
    [setFieldValue, validateField, validationSchema],
  );

  const handleSubmitJob = useCallback(
    async (status: string, navRoute?: string) => {
      if (!orgId) return;

      if (validateForm) {
        const errors = await validateForm();

        if (status !== 'published') {
          delete errors.interview_questions_attributes;
        }

        if (!isEmpty(errors)) return;
      }

      const jobRequest = job as JobRequest;
      const payload: JobRequest = {
        ...jobRequest,
        status: status as keyof typeof JobStatus,
        interview_questions_attributes:
          jobRequest.interview_questions_attributes.map((question, index) => {
            return {
              ...question,
              position: index + 1,
              suggested_answers: question?.suggested_answers || undefined,
            };
          }),
      };

      try {
        const response = jobRequest.id
          ? await updateJob(payload).unwrap()
          : await createJob(payload).unwrap();

        setValues({
          ...mapJobToJobForm(response as Job),
          organization_id: orgId,
          hiring_organization_id: payload.hiring_organization_id,
        });

        if (!jobRequest.id) {
          navigate(`${NAV_ROUTES.JOBS}/${response.id}`, { replace: true });

          onStepChange(activeStep + 1);
        } else if (navRoute) {
          navigate(navRoute);
        } else {
          onStepChange(activeStep + 1);
        }
      } catch (err) {
        const errorData = (err as FetchBaseQueryError).data as any;
        let errorMessage = 'An unknown error occurred.';
        const interviewQuestionsContent =
          errorData?.['interview_questions.content'];

        if (Array.isArray(interviewQuestionsContent)) {
          const firstErrorMessage = interviewQuestionsContent[0];

          if (firstErrorMessage) {
            errorMessage = firstErrorMessage;
          }
        }
        enqueueSnackbar({
          message: errorMessage,
          variant: 'error',
        });
      }
    },
    [
      enqueueSnackbar,
      navigate,
      job,
      orgId,
      createJob,
      updateJob,
      setValues,
      activeStep,
    ],
  );

  const handleBack = useCallback(() => {
    if (activeStep === 0) navigate(NAV_ROUTES.JOBS);

    onStepChange(activeStep - 1);
  }, [navigate, activeStep, onStepChange]);

  const handleNext = useCallback(async () => {
    try {
      if (validateForm) {
        const errors = await validateForm();

        // special handling:
        // * skip validation of intro_video_url
        // * skip validation of interview questions when non published state
        // otherwise next buttons dont work for other steps
        if (!isLastStep) {
          delete errors.intro_video_url;
          delete errors.interview_questions_attributes;
        } else if (errors.interview_questions_attributes) {
          onStepChange(1);
        }

        if (!isEmpty(errors)) return;
      }

      if (!isLastStep && (job as JobRequest).status === 'published')
        onStepChange(activeStep + 1);

      await handleSubmitJob(
        isLastStep ? 'published' : (job as JobRequest).status,
        isLastStep ? NAV_ROUTES.JOBS : undefined,
      );
    } catch (err) {
      enqueueSnackbar({
        message: ((err as FetchBaseQueryError).data as any)?.title[0],
        variant: 'error',
      });
    }
  }, [
    enqueueSnackbar,
    navigate,
    activeStep,
    isLastStep,
    job,
    validateForm,
    handleSubmitJob,
    onStepChange,
  ]);

  const handleGenerateQuestions = useCallback(async () => {
    try {
      const response = await generateQuestions(job as JobRequest).unwrap();
      setGeneratedQuestions(response);
    } catch (err) {
      enqueueSnackbar({
        message: ((err as FetchBaseQueryError).data as any)?.title[0],
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, job, orgId, generateQuestions]);

  const isLoading =
    isCreating || isUpdating || isGenerating || isLoadingDescription;

  const submitText = useMemo(() => {
    if (isLastStep) return isLoading ? 'Publishing' : 'Publish Job';
    if ((job as JobRequest).status !== 'published')
      return isLoading ? 'Saving' : 'Save & Next';

    return 'Next';
  }, [isLastStep, isLoading, job]);

  const renderedStep = useMemo(() => {
    switch (activeStep) {
      case 0:
        return (
          <JobInfoStep
            isLoading={isLoading}
            job={job as JobRequest}
            orgId={orgId as string}
            onChange={handleJobChange}
          />
        );

      case 1:
        return (
          <FormCard title="Interview Questions&nbsp;✨">
            <JobInterviewForm
              isLoading={isLoading}
              job={job as JobRequest}
              onChange={handleJobChange}
              onGenQuestions={handleGenerateQuestions}
            />
          </FormCard>
        );

      default:
        return (
          <JobPreview
            job={job as JobRequest}
            orgId={orgId as string}
            onStepChange={onStepChange}
          />
        );
    }
  }, [
    activeStep,
    isLoading,
    job,
    orgId,
    handleJobChange,
    handleGenerateQuestions,
    onStepChange,
  ]);

  return (
    <div
      className={clsx(
        'col-span-12 flex flex-col gap-y-7',
        activeStep === 0 && 'lg:col-span-9 xl:col-span-8',
      )}
    >
      {renderedStep}
      <FormActions
        cancel={{
          className: '!w-[166px]',
          disabled: isLoading,
          icon: activeStep === 0 ? 'X' : 'ArrowLeft',
          text: activeStep === 0 ? 'Cancel' : 'Back',
          onClick: handleBack,
        }}
        save={
          isLastStep && (job as JobRequest).status !== 'published'
            ? {
                variant: 'primary',
                className: '!w-[166px]',
                disabled: isLoading,
                icon: 'PenLine',
                text: 'Save Job',
                onClick: () =>
                  handleSubmitJob(
                    (job as JobRequest).status,
                    NEW_ROUTES.NEW_JOB,
                  ),
              }
            : undefined
        }
        submit={{
          className: '!w-[166px]',
          variant: isLastStep ? 'greenGradient' : 'primary',
          disabled: isLoading,
          icon: isLastStep ? 'Send' : 'PenLine',
          text: submitText,
          onClick: handleNext,
        }}
      />
    </div>
  );
}

export { JobForm };
