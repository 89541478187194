import { Auth, Terms } from '@modules/auth';
import {
  AuthCompliance,
  AuthEncryption,
  AuthForm,
  AuthHero,
  AuthPaper,
  Button,
  SignupForm,
  SignupInfo,
} from '@kerplunkai/common-components';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import {
  // createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { COMPLIANCE_DATA, NAV_ROUTES } from '@constants';
import { SignupRequest, UserResponse } from '@typings';
import { fakers } from '@utilities';
import {
  useCheckTokenMutation,
  useSignUpWithTokenMutation,
  useSignupMutation,
} from '@store/services';

import logo from '@assets/images/logos/Kerplunk-Logo-blk.svg';

function SignUp() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  const [signup, { isLoading: isSigningUp, error: signupError }] =
    useSignupMutation();
  const [
    signupWithToken,
    { isLoading: isSigningUpWithToken, error: signupWithTokenError },
  ] = useSignUpWithTokenMutation();
  const [checkToken, { isLoading: isCheckingToken, data: checkTokenData }] =
    useCheckTokenMutation();

  useEffect(() => {
    if (token !== null) checkToken(token);
  }, [checkToken, token]);

  useEffect(() => {
    if (signupError || signupWithTokenError) {
      enqueueSnackbar({
        message:
          (
            (signupError as FetchBaseQueryError)?.data as {
              status: {
                message: string;
              };
            }
          )?.status?.message || 'An error occurred. Please try again.',
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  }, [signupError, signupWithTokenError]);

  const handleSignup = useCallback(
    async (signupInfo: SignupInfo) => {
      const signupRequest: SignupRequest = {
        email: signupInfo.email,
        first_name: signupInfo.first_name,
        last_name: signupInfo.last_name,
        password: signupInfo.password,
        password_confirmation: signupInfo.confirmPassword,
      };

      try {
        let response: UserResponse;

        if (token != null) {
          response = await signupWithToken({
            first_name: signupInfo.first_name,
            last_name: signupInfo.last_name,
            password: signupInfo.password,
            password_confirmation: signupInfo.confirmPassword,
            invitation_token: token,
          }).unwrap();
        } else {
          response = await signup(signupRequest).unwrap();
        }

        // got a token; proceed into app
        if (response.token) {
          localStorage.setItem('token', response.token);
          return;
        }

        // no token but we have a message; go back to login and show the message
        if (response.message) {
          navigate(NAV_ROUTES.LOGIN, {
            state: { message: response.message },
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('signin error:', e);
      }
    },
    [navigate, signup, signupWithToken, token],
  );

  useEffect(() => {
    const sessionId = localStorage.getItem('stripe_session_id');
    if (!sessionId) {
      window.location.href = 'https://pay.kerplunk.com/b/8wMbII83n5kH21q8wX';
    }
  }, []);

  return (
    <Auth>
      <AuthPaper logo={logo}>
        <AuthForm
          subtitle={
            <div className="mt-2.5 text-slate-600">
              Already have an account?{' '}
              <Button
                className="font-medium text-sky-800"
                variant="text"
                onClick={() => navigate(NAV_ROUTES.LOGIN)}
              >
                Sign In
              </Button>
            </div>
          }
          title="Sign Up"
        >
          <SignupForm
            hideEmail={Boolean(token)}
            isLoading={isSigningUp || isCheckingToken || isSigningUpWithToken}
            onSignIn={() => navigate(NAV_ROUTES.LOGIN)}
            onSignup={handleSignup}
          />
        </AuthForm>
        <div className="mt-10 grid justify-items-center">
          <AuthEncryption
            className="max-xs:w-[280px] lg:break-words"
            textClassName="max-xs:text-[8px]"
            icons="LockKeyhole"
            text="Secured by 256-bit AES and 256-bit SSL/TLS encryption"
          />
          <div className="mt-2 flex flex-wrap justify-center gap-2">
            {COMPLIANCE_DATA.map(item => (
              <AuthCompliance
                key={item.id}
                text={item.text}
                icons={item.icon}
              />
            ))}
          </div>
        </div>
        <Terms />
      </AuthPaper>
      <AuthHero
        tokenOrg={checkTokenData ? checkTokenData[0] : undefined}
        subtitle="Unlock the future of staffing, where organizations across all sectors leverage AI to source, recruit, and retain the world's top talent. Join us today to connect with leading companies and accelerate your career on Kerplunk."
        title="Build your career on Kerplunk."
        heroUserList={fakers.fakeUsers()}
      />
    </Auth>
  );
}

export { SignUp };
