import { Disclosure, Lucide } from '@kerplunkai/common-components';
import { HighlightableText } from '@modules/interviewDetails/components';
import { InterviewQuestionAnalysisDetail } from '@typings';

interface InterviewQuestionTranscriptNotesProps {
  interviewQuestion: InterviewQuestionAnalysisDetail;
  searchTerm?: string;
}

function InterviewQuestionTranscriptNotes({
  interviewQuestion,
  searchTerm,
}: InterviewQuestionTranscriptNotesProps) {
  return (
    <Disclosure className="rounded-[0.6rem]">
      <Disclosure.Button>
        <div className="flex border-b border-dashed border-slate-200/60 pb-5 text-[0.94rem] font-medium">
          <Lucide icon="ChevronDown" className="mr-2 size-5 stroke-[1.3]" />
          <div className="text-base">AI Notes</div>
        </div>
      </Disclosure.Button>
      <Disclosure.Panel className="text-sm font-normal leading-relaxed text-slate-600 dark:text-slate-500">
        <div className="grid grid-cols-12 gap-4">
          {interviewQuestion.commentary && (
            <div className="col-span-12 px-4">
              <ul className="list-disc">
                {interviewQuestion.commentary?.map(text => {
                  return (
                    <li key={text} className="mb-4">
                      <HighlightableText text={text} searchTerm={searchTerm} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {!interviewQuestion.commentary && (
            <div className="col-span-12">No AI Notes available</div>
          )}
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
}

export { InterviewQuestionTranscriptNotes };
