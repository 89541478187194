import { CodeBlock, Job, JobListingDetail, JobQuestion } from '@typings';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface Interview {
  ai_score: number;
  id: string;
  icon?: string;
  job_application_id: string;
  status: keyof typeof InterviewStatus;
  interview_type: string;
  rating: number;
  feedback: string;
  created_at: string;
  updated_at: string;
  candidate_id: string;
  candidate_name: string;
  candidate_email: string;
  candidate_display_name: string;
  candidate_avatar_url: string;
  candidate_linked_in_url?: string;
  candidate_instagram_url?: string;
  candidate_github_url?: string;
  candidate_twitter_x_url?: string;
  candidate_facebook_url?: string;
  candidate_portfolio_url?: string;
  candidate_tiktok_url?: string;
  organization: string;
  organization_logo_url?: string;
  job_listing: JobListingDetail;
  job_title: string;
  pros_and_cons: FeedbackContent;
  score_breakdown?: InterviewScoreBreakdown;
  commentary?: Commentary;
  detailed_commentary?: DetailedCommentary;
  interview_question_analyses?: InterviewQuestionAnalysis[];
  resume?: InterviewResume;
  full_interview_video_url?: string;
  full_interview_transcript_url?: string;
  interview_attendees?: InterviewAttendee[];
  interview_questions: JobQuestion[];
  video_provider?: 'aws_ivs' | 'videosdk';
  pdf_url?: string;
  [key: string]:
    | string
    | number
    | Date
    | Commentary
    | Job
    | DetailedCommentary
    | InterviewScoreBreakdown
    | InterviewScore
    | InterviewResume
    | FeedbackContent
    | InterviewQuestionAnalysis[]
    | undefined
    | boolean
    | InterviewAttendee[]
    | JobQuestion[];
}

export interface InterviewNotesResponse {
  meta: {
    current_page: number;
    next_page?: number;
    prev_page?: number;
    total_count: number;
    total_pages: number;
  };
  results: InterviewNote[];
}

export interface DetailedCommentary {
  adaptability: string;
  communication: string;
  conclusion: string;
  continuous_improvement: string;
  experience: string;
  financial_impact: string;
  overview: string;
  professionalism: string;
}
export interface FeedbackContent {
  pros: string;
  cons: string;
}
export interface InterviewQuestionAnalysis {
  content: string;
  id: string;
  interview_question: InterviewQuestionAnalysisDetail;
  position: number;
  transcript: InterviewQuestionTranscript[];
  transcription_job_status: string; // TODO: get types ie "COMPLETED"
  transcription_job_name: string;
  video_transcript_url: string;
  video_url: string;
  start_time: number;
  code_blocks: CodeBlock[];
  category?: keyof typeof InterviewQuestionCategory;
  user_input?: string | null;
  suggested_answers?: string[];
}

export interface InterviewQuestionAnalysisDetail {
  commentary?: string[];
  score?: number;
}

export interface InterviewQuestionTranscript {
  text: string;
  start_time: string;
  end_time: string;
}

export interface EmptyTranscript {
  results?: {
    transcripts: {
      transcript: string;
    }[];
  };
}

export interface InterviewScoreBreakdown {
  interview: InterviewScore;
  resume: InterviewScore;
}

export interface InterviewScore {
  resume_score: number;
  x_factor_score: number;
  interview_score: number;
  technical_score: number;
  culture_fit_score: number;
  soft_skills_score: number;
}

export interface Commentary {
  analysis: string;
  feedback: string;
  culture_fit: string;
  soft_skills: string;
}

export interface OrgInterviewRequest {
  interviewId: string;
  organizationId: string;
  shareToken?: string;
}

export interface InterviewNotesRequest {
  interviewId: string;
  organizationId: string;
}

export interface DeleteInterviewNoteRequest {
  interviewId: string;
  organizationId: string;
  interviewNoteId: string;
}

export interface UpdateInterviewNoteRequest {
  interviewId: string;
  organizationId: string;
  interviewNoteId: string;
  text: string;
}

export interface InterviewNoteRequest {
  interviewId: string;
  organizationId: string;
  text: string;
}

export interface InterviewNote {
  id: string;
  content: string;
  created_at: string;
  updated_at: string;
  creator: {
    display_name: string;
    id: string;
    avatar_url?: string;
  };
}

export interface InterviewShareUrlRequest {
  interviewId: string;
  organizationId: string;
  jobDescription: string;
  jobTitle: string;
}

export interface InterviewShareUrlResponse {
  access_url: string;
  token: string;
}

export interface InterviewResume {
  id: string;
  file_type: string;
  uploaded_at: Date;
  filename: string;
  url: string;
  analyses: ResumeAnalysis[];
}

export enum ScoringCategory {
  novice = 'novice',
  intermediate = 'intermediate',
  advanced = 'advanced',
  expert = 'expert',
}

export interface ScoringCategoryStyles {
  background: string;
  border: string;
  text: string;
  labelText?: string;
}

export interface ResumeDetails {
  phone_number?: string;
  current_title?: string;
  current_location?: string;
  experience_level?: string;
}

export interface ResumeAnalysis {
  id: string;
  scores: InterviewScore;
  commentary: Commentary;
  details: ResumeDetails;
  ai_score: number;
}

export interface InterviewScoringDetails {
  percentXFactor: number;
  percentCultural: number;
  percentTechnical: number;
  percentSoftskills: number;
  percentResume: number;
}

export enum InterviewQuestionCategory {
  culture = 'Culture',
  personal = 'Personal',
  soft_skills = 'Soft Skills',
  technical = 'Technical',
  x_factor = 'X-Factor',
  yes_no = 'Yes/No',
}

export interface InterviewRequest {
  title: string;
  description: string;
  interview_type: string;
  status: string;
  job_listing_id: string;
  interview_at: string;
  interview_attendees_attributes: InterviewAttendee[];
  send_emails: boolean;
  organization_id?: string;
  id?: string;
  interview_at_day?: string;
  interview_at_time?: string;
  job_listing?: Job;
  interview_candidate?: string;
}

export interface InterviewAttendee {
  id?: string;
  email: string;
  is_candidate?: boolean;
  _destroy?: boolean;
  [key: string]: string | boolean | undefined;
}

export enum InterviewStatus {
  'started' = 'Started',
  'completed' = 'Completed',
  'processing' = 'Processing',
  'processed' = 'Processed',
  'pending' = 'Pending',
  'cancelled' = 'Cancelled',
  'scheduled' = 'Scheduled',
}

export enum InterviewQuestionStatus {
  skipped = 'skipped',
  completed = 'completed',
}

export interface CreateQuestionInterviewRoomResponse {
  room_id?: string;
  token?: string;
  stream_key?: string;
  ingest_endpoint?: string;
}

export interface SubmitInterviewQuestionRequest {
  status: InterviewQuestionStatus;
  userId: string;
  interviewId: string;
  questionId: string;
}

export interface SubmitInterviewQuestionResponse {
  status: InterviewQuestionStatus;
  video_file_id: string;
}

export interface CreateInterviewRoomResponse {
  room_id?: string;
  token?: string;
  ingest_endpoint?: string;
  stream_key?: string;
}

export interface CreateInterviewRoomRequest {
  userId: string;
  interviewId: string;
}

export interface CreateQuestionInterviewRoomRequest {
  userId: string;
  interviewId: string;
  questionId: string;
}

export interface UpdateInterviewResponse {
  id: string;
  job_application_id: string;
  status: string | null;
  interview_type: string | null;
  rating: string | null;
  feedback: string | null;
  questions: string[];
}

export interface UpdateInterviewRequest {
  userId: string;
  interviewId: string;
  status: string;
  feedback?: string;
  rating?: number;
}
