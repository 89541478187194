import { JobQuestion } from '@typings';

import { TextSuggestedAnswers } from './textSuggestedAnswers.component';
import { YesNoSuggestedAnswers } from './yesNoSuggestedAnswers.component';

interface QuestionSuggestedAnswersProps {
  show?: boolean;
  question: JobQuestion;
  isLoading?: boolean;
  questionIndex: number;
  onRemoveAnswer?: (questionIndex: number, answerIndex: number) => void;
  onAnswerChange?: (value: string, answerIndex: number, index: number) => void;
  onAddAnswer?: () => void;
}

function QuestionSuggestedAnswers({
  question,
  show = true,
  isLoading = false,
  questionIndex,
  onAnswerChange,
  onRemoveAnswer,
  onAddAnswer,
}: QuestionSuggestedAnswersProps) {
  if (!show) return null;

  switch (question.category) {
    case 'yes_no':
      return (
        <YesNoSuggestedAnswers
          onAnswerChange={onAnswerChange}
          onRemoveAnswer={onRemoveAnswer}
          questionIndex={questionIndex}
          isLoading={isLoading}
          question={question}
        />
      );

    default:
      return (
        <TextSuggestedAnswers
          onAddAnswer={onAddAnswer}
          question={question}
          questionIndex={questionIndex}
          onRemoveAnswer={onRemoveAnswer}
          onAnswerChange={onAnswerChange}
        />
      );
  }
}

export { QuestionSuggestedAnswers };
