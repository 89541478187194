/* eslint-disable react/no-array-index-key */
import { Disclosure, Lucide } from '@kerplunkai/common-components';
import { InterviewQuestionAnalysis, InterviewQuestionCategory } from '@typings';

import { CodeMirrorBlock } from '@components';
import {
  InterviewCategoryChip,
  InterviewQuestionTranscriptDetails,
  InterviewQuestionTranscriptNotes,
  InterviewScoringChip,
} from '@modules/interviewDetails/components';

interface InterviewTranscriptAnalysisProps {
  analysis: InterviewQuestionAnalysis;
  searchTerm?: string;
}

function InterviewTranscriptAnalysis({
  analysis,
  searchTerm,
}: InterviewTranscriptAnalysisProps) {
  const isHideScoreAndNotes =
    analysis?.category === 'yes_no' &&
    (!analysis?.suggested_answers || analysis.suggested_answers.length <= 0);

  return (
    <Disclosure className="rounded-[0.6rem]">
      <Disclosure.Button>
        <div className="border-b border-dashed border-slate-200/60 pb-5 text-[0.94rem] font-medium">
          <div className="flex items-start text-base md:items-center">
            <div className="mr-2 size-5">
              <Lucide icon="ChevronDown" className="stroke-[1.3]" />
            </div>
            {analysis.content}
          </div>
          {analysis.code_blocks
            ?.filter(({ content, language }) => content && language)
            .map((codeBlock, curIndex) => (
              <div key={`code-block-${curIndex}`} className="ml-[28px] mt-2">
                <CodeMirrorBlock block={codeBlock} editable={false} />
              </div>
            ))}
          <div className="ml-[28px] mt-2 flex items-center">
            <div className="text-xs font-normal">
              Question {analysis.position}
            </div>
            {analysis.category && (
              <InterviewCategoryChip
                categoryName={InterviewQuestionCategory[analysis.category]}
                className="rounded-sm"
              />
            )}
            {analysis.interview_question.score != null &&
              !isHideScoreAndNotes && (
                <InterviewScoringChip
                  score={analysis.interview_question.score}
                  className="rounded-sm"
                />
              )}
          </div>
        </div>
      </Disclosure.Button>
      <Disclosure.Panel className="text-sm font-normal leading-relaxed text-slate-600 dark:text-slate-500">
        <div className="rounded-md">
          {!isHideScoreAndNotes && (
            <InterviewQuestionTranscriptNotes
              interviewQuestion={analysis.interview_question}
              searchTerm={searchTerm}
            />
          )}
          <InterviewQuestionTranscriptDetails
            transcripts={analysis.transcript}
            searchTerm={searchTerm}
          />
          {analysis.category === 'yes_no' && analysis.user_input && (
            <div className="mt-2 w-full rounded-lg border border-slate-200/60 bg-[#558408]/10 p-4 font-medium text-slate-800">
              Final Answer: {analysis.user_input === 'yes' ? 'Yes' : 'No'}
            </div>
          )}
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
}

export { InterviewTranscriptAnalysis };
