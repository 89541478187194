/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  FormInput,
  FormLabel,
  Lucide,
} from '@kerplunkai/common-components';
import { ChangeEvent, useCallback } from 'react';

import { IntegrationFormProps } from '@typings';

export interface AshbyFormProps extends IntegrationFormProps {}

function AshbyForm({
  errors,
  integration,
  integrationType,
  isEdit = false,
  isLoading,
  isNew = false,
  values,
  onEdit,
  onChange,
  onDelete,
  onSetFieldValue,
}: AshbyFormProps) {
  const handleAPIKeyChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      if (!onSetFieldValue) return;

      onSetFieldValue(name, value);
    },
    [onSetFieldValue],
  );

  return (
    <div className="flex flex-col gap-6">
      {(isNew || isEdit) && (
        <>
          <div className="flex max-w-full flex-col gap-3.5">
            <FormLabel htmlFor="name">
              <div className="flex">
                Integration Name
                <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
                  Required
                </div>
              </div>
              <div className="mt-1.5 text-left text-xs text-slate-500">
                Input the name of your&nbsp;{integrationType?.name}
                &nbsp;integration
              </div>
            </FormLabel>
            <div>
              <FormInput
                id="name"
                disabled={isLoading}
                error={Boolean(errors.name)}
                name="name"
                placeholder="Integration Name"
                value={values.name}
                onChange={onChange}
              />
              {Boolean(errors.name) && (
                <div className="mt-2 text-left text-xs text-danger">
                  {errors.name}
                </div>
              )}
            </div>
          </div>
          <div className="flex max-w-full flex-col gap-3.5">
            <FormLabel htmlFor="api_key">
              <div className="flex">
                API Key
                <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
                  Required
                </div>
              </div>
              <div className="mt-1.5 text-left text-xs text-slate-500">
                Input the API key of your&nbsp;{integrationType?.name}
                &nbsp;integration
              </div>
            </FormLabel>
            <div>
              <FormInput
                id="api_key"
                disabled={isLoading}
                error={Boolean(errors.api_key)}
                name="api_key"
                placeholder="API Key"
                value={values.api_key}
                onChange={handleAPIKeyChange}
              />
              {Boolean(errors.api_key) && (
                <div className="mt-2 text-left text-xs text-danger">
                  {errors.api_key}
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {integration && !isEdit && (
        <div className="flex max-w-full flex-col gap-3.5">
          <div className="text-base font-medium">
            Integration Name: {integration.name}
          </div>
          <div className="text-base font-medium">
            API Key: {integration.api_key}
          </div>
          {integration.id && (
            <>
              <div className="text-base font-medium">
                Kerplunk API Key: {integration.id}
              </div>
              <div className="text-sm text-slate-500">
                If you wish to use Kerplunk with Ashby Assessments, then use
                this Kerplunk API Key when setting up the integration in Ashby.
              </div>
              <a
                href="https://app.ashbyhq.com/admin/integrations/marketplace/kerplunk"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 underline hover:text-blue-800"
              >
                Create new integration in Ashby for Kerplunk Assessments
              </a>
            </>
          )}
          <div className="flex gap-6">
            <Button
              variant="soft-secondary"
              className="flex w-fit gap-1.5 px-3.5 py-2.5 text-slate-500"
              onClick={onEdit}
            >
              <Lucide icon="PenLine" />
              <div className="text-sm font-semibold">Edit</div>
            </Button>
            <Button
              className="border-orange-700 bg-orange-700/80 px-3 py-2 text-white"
              disabled={isLoading}
              variant="soft-sky-outline"
              onClick={onDelete}
            >
              <div className="flex gap-1.5 font-normal">
                <Lucide icon="X" />
                Disconnect
              </div>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export { AshbyForm };
