/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import clsx from 'clsx';
import * as Yup from 'yup';
import { Element, scrollSpy } from 'react-scroll';
import { Formik, FormikErrors, FormikProps } from 'formik';
import {
  Loading,
  Lucide,
  NavCard,
  Stepper,
} from '@kerplunkai/common-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { JobForm } from '@modules/jobs/jobForms';
import { JobRequest } from '@typings';
import { getInitialJobValue } from '@utilities/jobs';
import { isEmpty } from 'lodash';
import {
  selectOrgClients,
  selectOrgJob,
  selectOrganizations,
  selectSelectedOrg,
  selectSelectedOrgId,
} from '@store/selectors';
import {
  useLazyGetOrgJobQuery,
  useLazyJobCategoriesQuery,
  useLazyJobSubcategoriesQuery,
  useOrgClientsQuery,
} from '@store/services';

const PAGINATION_INFO = {
  pageIndex: 1,
  pageSize: 1000,
};

function JobDetails() {
  const jobFormRef = useRef<FormikProps<JobRequest>>(null);
  const { jobId } = useParams<{ jobId: string }>();

  const orgId = useSelector(selectSelectedOrgId);
  const organization = useSelector(selectSelectedOrg);
  const organizations = useSelector(selectOrganizations());
  const orgJob = useSelector(selectOrgJob(orgId as string, jobId as string));
  const clients = useSelector(
    selectOrgClients({
      orgId: orgId as string,
      pagination: PAGINATION_INFO,
      filters: '',
    }),
  );

  const [getJobCategories] = useLazyJobCategoriesQuery();
  const [getJobSubcategories] = useLazyJobSubcategoriesQuery();
  const { isLoading: isLoadingClients } = useOrgClientsQuery({
    orgId: orgId as string,
    pagination: PAGINATION_INFO,
    filters: '',
  });
  const [getOrgJob, { isLoading: isGettingJobOrg }] = useLazyGetOrgJobQuery();

  const [isLoading, setIsLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    (async () => {
      if (orgId) {
        await getJobCategories({
          orgId: orgId as string,
          pagination: PAGINATION_INFO,
          filters: '',
        });
        await getJobSubcategories({
          orgId: orgId as string,
          pagination: PAGINATION_INFO,
          filters: '',
        });
      }

      if (orgId && jobId && jobId !== 'new')
        await getOrgJob({ organizationId: orgId, jobId });

      setIsLoading(false);
    })();
  }, [orgId, jobId, getOrgJob, getJobCategories, getJobSubcategories]);

  useEffect(() => {
    if (jobId === 'new') setActiveStep(0);
  }, [jobId]);

  useEffect(() => {
    scrollSpy.update();
  }, []);

  const handleStepChange = useCallback(async (stepNum: number) => {
    let validationErrors: FormikErrors<JobRequest> = {};

    if (jobFormRef.current) {
      validationErrors = await jobFormRef.current.validateForm();

      delete validationErrors.interview_questions_attributes;
    }

    if (isEmpty(validationErrors)) setActiveStep(stepNum);
  }, []);

  if (
    isLoading ||
    isGettingJobOrg ||
    !organizations ||
    !organization ||
    !clients ||
    isLoadingClients
  )
    return <Loading />;

  const validationSchema = Yup.object({
    title: Yup.string()
      .max(70, 'Must be 70 characters or less')
      .required('Job Title is required'),
    hiring_organization_id:
      organization.industry.name === 'Recruiting'
        ? Yup.string().required('Hiring Organization is required')
        : Yup.string(),
    industry_id: Yup.string().required('Industry is required'),
    description: Yup.string().required('Job description is required'),
    intro_video_url: Yup.string()
      .optional()
      .nullable()
      .matches(
        /^(https?:\/\/(?:www\.)?[^\s/$.?#].[^\s]*)?$|^$/,
        'Must be a valid URL',
      ),
    interview_questions_attributes: Yup.array().test(
      'contains-valid-questions',
      'At least one question is required to publish',
      questions => {
        if (!questions || questions.length === 0) {
          return false;
        }

        return questions.some(question => question._destroy !== true);
      },
    ),
  });

  return (
    <div className="grid grid-cols-12 gap-x-6 gap-y-10">
      <div className="col-span-12">
        <div className="grid grid-cols-12 xl:grid-cols-10">
          <div
            className={clsx(
              'col-span-12',
              activeStep === 0 && 'lg:col-span-9 xl:col-span-8',
            )}
          >
            <Element name="postAJob">
              <Stepper
                activeStep={activeStep}
                steps={[
                  {
                    text: 'Job Information',
                  },
                  {
                    text: '✨AI Questions',
                  },
                  {
                    text: 'Preview & Publish',
                  },
                ]}
                onStepChange={handleStepChange}
              />
            </Element>
          </div>
        </div>
        <div className="mt-3.5 grid grid-cols-12 gap-x-6 gap-y-7 lg:gap-y-10 xl:grid-cols-10">
          <Formik
            innerRef={jobFormRef}
            initialValues={getInitialJobValue(
              orgJob,
              organization,
              clients,
              jobId === 'new',
            )}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={() => {}}
          >
            <JobForm
              activeStep={activeStep}
              isLastStep={activeStep === 2}
              validationSchema={validationSchema}
              onStepChange={setActiveStep}
            />
          </Formik>
          <div
            className={clsx(
              'relative order-first col-span-12 lg:order-last lg:col-span-3 xl:col-span-2',
              activeStep !== 0 && 'hidden',
            )}
          >
            <div className="sticky top-[104px]">
              <NavCard
                activeClassName="text-sky-800  before:content-[''] before:left-0 before:top-0 before:rounded-lg before:absolute before:w-0.5 before:h-10 before:bg-sky-800"
                title="Post a job"
                navItems={[
                  { text: 'Job Information', to: 'jobInformation' },
                  { text: 'Job Description', to: 'jobDescription' },
                  { text: 'Additional Details', to: 'additionalDetails' },
                  { text: 'Compensation', to: 'compensation' },
                ]}
              />
              <div className="relative mt-7 rounded-[0.6rem] border border-yellow-500/10 bg-yellow-500/5 p-5 dark:border-0 dark:bg-darkmode-600">
                <Lucide
                  icon="Lightbulb"
                  className="absolute right-0 top-0 mr-3 mt-5 size-12 stroke-yellow-500"
                />
                <h2 className="text-lg font-medium text-slate-400 ">Tips</h2>
                <div className="mt-3 text-sm font-normal text-slate-400">
                  Job Postings
                </div>
                <div className="mt-4 text-xs font-normal text-slate-400 opacity-80">
                  <div>
                    Tips for Posting: A compelling job post attracts the best
                    candidates. Ensure that your job title is specific, the
                    description is clear and provides an insight into your
                    company culture. Highlight the key requirements but keep the
                    post concise to maintain the reader&apos;s interest.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { JobDetails };
