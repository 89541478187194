/* eslint-disable no-underscore-dangle */
import { FormCard } from '@kerplunkai/common-components';

import { InterviewRequest } from '@typings';

interface MeetingGuestsProps {
  interview: InterviewRequest;
}

function MeetingGuests({ interview }: MeetingGuestsProps) {
  return (
    <FormCard title="Candidate & Additional Guests">
      <div className="flex items-center pt-5 font-medium text-slate-600 first:mt-0 first:pt-0">
        {interview.interview_candidate}
        <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-400 dark:bg-darkmode-300 dark:text-slate-400">
          Candidate
        </div>
      </div>
      {interview.interview_attendees_attributes.map(attendee => {
        if (attendee._destroy) return null;

        return (
          <div
            key={attendee.email}
            className="pt-5 font-medium text-slate-600 first:mt-0 first:pt-0"
          >
            {attendee.email}
          </div>
        );
      })}
    </FormCard>
  );
}

export { MeetingGuests };
