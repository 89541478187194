import {
  Button,
  FormControl,
  FormInputControl,
  FormSwitch,
  Lucide,
} from '@kerplunkai/common-components';
import { useField } from 'formik';

import { ChangeEvent, useCallback } from 'react';
import { InterviewAttendee, InterviewRequest } from '@typings';
import { InterviewAttendeesTable } from '@modules/meetings/components';

interface InterviewAttendeesFormProps {
  isLoading: boolean;
  interview: InterviewRequest;
  onChange: (
    name: string,
    value: InterviewAttendee[] | boolean | string,
  ) => void;
}

function InterviewAttendeesForm({
  isLoading,
  interview,
  onChange,
}: InterviewAttendeesFormProps) {
  const [, attendeesMeta] = useField('interview_attendees_attributes');
  const [, candidateMeta] = useField('interview_candidate');

  const handleInputChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      onChange(name, value);
    },
    [onChange],
  );

  return (
    <div className="mt-5">
      <FormInputControl
        className="mt-5"
        description="Add email address of the candidate your are interviewing."
        errorText={candidateMeta.error}
        inputProps={{
          disabled: isLoading,
          onChange: handleInputChange,
          placeholder: 'Enter email address',
        }}
        isRequired
        label="Candidate"
        name="interview_candidate"
        value={interview.interview_candidate as string}
      />
      <FormControl
        className="mt-5 xl:!items-start"
        description="Add email address of guest you wish to invite to this meeting."
        label="Additional Guests"
      >
        <>
          <InterviewAttendeesTable
            isLoading={isLoading}
            interview={interview}
            onChange={onChange}
          />
          <Button
            className="mt-4 w-full border-dashed"
            disabled={isLoading}
            variant="soft-sky-outline"
            onClick={() =>
              onChange('interview_attendees_attributes', [
                ...interview.interview_attendees_attributes,
                {
                  email: '',
                  is_candidate: false,
                },
              ])
            }
          >
            <Lucide icon="Plus" className="mr-2 size-4 stroke-[1.3]" />
            Add Guest
          </Button>
          {attendeesMeta.error && (
            <div className="mt-4 text-xs text-danger">
              {attendeesMeta.error}
            </div>
          )}
        </>
      </FormControl>
      <FormControl
        className="mt-5 flex items-center"
        description="Would you like to send invitation emails to guests?"
        label="Send Email Invites to Guests"
      >
        <div className="flex items-center">
          <FormSwitch.Input
            checked={interview.send_emails}
            disabled={isLoading}
            type="checkbox"
            onChange={() => onChange('send_emails', !interview.send_emails)}
          />
          <p className="ml-2 text-slate-400">Send Email Invites to Guests</p>
        </div>
      </FormControl>
    </div>
  );
}

export { InterviewAttendeesForm };
