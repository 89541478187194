/* eslint-disable react/no-array-index-key */
import { Button, Lucide, TableBase } from '@kerplunkai/common-components';
import { JobQuestion } from '@typings';

import { CorrectAnswers } from './correctAnswers.component';

interface TextSuggestedAnswersProps {
  question: JobQuestion;
  isLoading?: boolean;
  onRemoveAnswer?: (questionIndex: number, answerIndex: number) => void;
  onAnswerChange?: (value: string, answerIndex: number, index: number) => void;
  questionIndex: number;
  onAddAnswer?: () => void;
}

function TextSuggestedAnswers({
  question,
  isLoading = false,
  onRemoveAnswer,
  onAnswerChange,
  questionIndex,
  onAddAnswer,
}: TextSuggestedAnswersProps) {
  return (
    <TableBase.Tr className="bg-[#F4F6F8]">
      <TableBase.Td className="!whitespace-normal align-top" colSpan={2}>
        <div className="flex flex-col">
          <div className="flex items-start font-medium text-slate-600">
            Suggested Answers
            <span className="ml-2.5 rounded-md border border-[#4620CA]/20 bg-[#F4EFFC] px-2 py-0.5 text-xs text-[#4620CA] ">
              Beta
            </span>
          </div>
          <div className="mt-1.5 text-xs leading-relaxed text-slate-500">
            Assess a candidates response to the question above. Suggested
            answers are based on their similarity to the expected answers.
          </div>
        </div>
      </TableBase.Td>
      <TableBase.Td />
      <TableBase.Td className="w-full border-l !px-0" colSpan={3}>
        {question.suggested_answers?.map((answer, i) => {
          return (
            <CorrectAnswers
              key={i}
              answerIndex={i}
              questionIndex={questionIndex}
              value={answer || ''}
              onRemoveAnswer={onRemoveAnswer}
              isLoading={isLoading}
              onChange={value => onAnswerChange?.(value, i, questionIndex)}
            />
          );
        })}
        <div className="mb-auto w-full px-3">
          <Button
            className="mt-3 w-full border-dashed border-[#558408] text-[#558408]"
            disabled={isLoading}
            variant="soft-sky-outline"
            onClick={onAddAnswer}
          >
            <Lucide icon="Plus" className="mr-2 size-4 stroke-[1.3]" />
            Add Another Answer
          </Button>
        </div>
      </TableBase.Td>
    </TableBase.Tr>
  );
}

export { TextSuggestedAnswers };
