/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import { CSS } from '@dnd-kit/utilities';
import {
  FormSelect,
  FormTextarea,
  Lucide,
  TableBase,
} from '@kerplunkai/common-components';
import { twMerge } from 'tailwind-merge';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSortable } from '@dnd-kit/sortable';

import { CodeBlock, JobQuestion } from '@typings';
import { selectJobQCategoryTypes } from '@store/selectors';

import { QuestionCodeBlocks } from './questionCodeBlocks.component';
import { QuestionRowActions } from './questionRowActions.component';
import { QuestionSuggestedAnswers } from './suggestedAnswers';

interface JobInterviewQuestionRowProps {
  isLoading: boolean;
  orgId: string;
  question: JobQuestion;
  onQuestionChange: (
    value: string | CodeBlock[],
    name: string,
    index: number,
    clearSuggestedAnswers?: boolean,
  ) => void;
  onAnswerChange: (value: string, answerIndex: number, index: number) => void;
  onRemoveQuestion: (index: number) => void;
  onRemoveAnswer: (questionIndex: number, answerIndex: number) => void;
  onToggleLock: (index: number) => void;
  onAnswerClear: (index: number) => void;
}

function JobInterviewQuestionRow({
  isLoading,
  orgId,
  question,
  onQuestionChange,
  onRemoveQuestion,
  onToggleLock,
  onAnswerClear,
  onRemoveAnswer,
  onAnswerChange,
}: JobInterviewQuestionRowProps) {
  const isShowSuggestedAnswers = !!question.suggested_answers?.length;
  const hasSuggestedAnswers = !!question?.suggested_answers?.some(
    answer => answer,
  );

  const isYesNoQuestion = question.category === 'yes_no';
  const questionIndex = question.position - 1;

  const handleAddCorrectAnswer = () => {
    onAnswerChange('', question.suggested_answers?.length || 0, questionIndex);
  };

  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: question.position,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '38px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [question.content]);

  const categoryTypes = useSelector(selectJobQCategoryTypes(orgId as string));

  const handleBookmarkClick = () => {
    if (!isShowSuggestedAnswers) {
      handleAddCorrectAnswer();
      return;
    }

    if (!hasSuggestedAnswers) {
      onAnswerClear(questionIndex);
    }
  };

  return (
    <>
      <TableBase.Tr ref={setNodeRef} style={style}>
        {isDragging ? (
          <TableBase.Td
            style={{ background: 'rgba(127, 207, 250, 0.3)' }}
            colSpan={4}
          >
            &nbsp;
          </TableBase.Td>
        ) : (
          <>
            <TableBase.Td className="whitespace-nowrap text-center">
              {question.position}
            </TableBase.Td>
            <TableBase.Td
              className={twMerge([
                question.generated_by_ai || question.locked
                  ? 'min-w-40 text-left !pr-0'
                  : 'min-w-40 !pr-0',
              ])}
            >
              {question.generated_by_ai || question.locked ? (
                (categoryTypes || {})[question.category]
              ) : (
                <FormSelect
                  disabled={isLoading}
                  name="category"
                  className="text-slate-500"
                  value={question.category}
                  onChange={({ target: { name, value } }) => {
                    onQuestionChange(
                      value,
                      name,
                      questionIndex,
                      isYesNoQuestion || value === 'yes_no', // clear suggested answers if switching to/from yes/no question
                    );
                  }}
                >
                  {categoryTypes &&
                    Object.keys(categoryTypes).map(value => (
                      <option key={value} value={value}>
                        {categoryTypes[value]}
                      </option>
                    ))}
                </FormSelect>
              )}
            </TableBase.Td>

            <TableBase.Td
              className="whitespace-nowrap !pr-0 text-right "
              onClick={handleBookmarkClick}
            >
              <Lucide
                icon="BookmarkCheck"
                className={twMerge([
                  isShowSuggestedAnswers
                    ? 'fill-[#558408] text-white'
                    : 'fill-none text-slate-400',
                  hasSuggestedAnswers &&
                    'pointer-events-none fill-slate-400 opacity-50',
                ])}
              />
            </TableBase.Td>
            <TableBase.Td className="w-full min-w-72 !pl-3">
              <div className="flex items-center">
                <FormTextarea
                  ref={textareaRef}
                  className={
                    question.locked
                      ? 'resize-none disabled:border-[#A0D846] disabled:bg-lime-400/10 disabled:text-[#558408] disabled:placeholder-[#558408]'
                      : 'resize-none text-slate-500'
                  }
                  disabled={isLoading || question.locked}
                  name="content"
                  placeholder="Enter your question here"
                  value={question.content}
                  onChange={({ target: { name, value } }) =>
                    onQuestionChange(value, name, questionIndex)
                  }
                />
              </div>

              <QuestionCodeBlocks
                codeBlocks={question.code_blocks}
                onQuestionChange={onQuestionChange}
                questionIndex={questionIndex}
              />
            </TableBase.Td>

            <QuestionRowActions
              isLoading={isLoading}
              onToggleLock={() => onToggleLock(questionIndex)}
              onRemoveQuestion={() => onRemoveQuestion(questionIndex)}
              isLocked={question.locked}
              dndAttributes={attributes}
              dndListeners={listeners}
            />
          </>
        )}
      </TableBase.Tr>

      <QuestionSuggestedAnswers
        question={question}
        show={isShowSuggestedAnswers}
        questionIndex={questionIndex}
        onAnswerChange={onAnswerChange}
        onRemoveAnswer={onRemoveAnswer}
        onAddAnswer={handleAddCorrectAnswer}
      />
    </>
  );
}

export { JobInterviewQuestionRow };
